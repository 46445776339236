import React from "react";
import ResList from "./ResList";

const MainRes = () => {
  return (
    <div>
      <ResList />
    </div>
  );
};

export default MainRes;
